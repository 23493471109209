<template>
  <div class="game">
    <div class="game-main">
      <div class="table_wrapper">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th
                v-for="(row, index) in this.list.numbers"
                :key="index"
                colspan="2"
              >
                {{ index + 1 }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, r_index) in this.table" :key="r_index">
              <td>
                <b>{{ r_index + 1 }}</b>
              </td>
              <template v-for="(col, c_index) in row">
                <td :key="c_index + '_td_1'">
                  {{ col }}
                </td>
                <td :key="c_index + '_td_2'">
                  <input
                    v-model="answer[c_index * maxY + r_index]"
                    :class="{
                      'bg-danger text-light':
                        isChecked(c_index * maxY + r_index) &&
                        !isTrue(c_index * maxY + r_index),
                      'bg-success text-light':
                        isChecked(c_index * maxY + r_index) &&
                        isTrue(c_index * maxY + r_index),
                    }"
                    :name="'input' + (c_index * maxY + r_index)"
                    :readonly="
                      isChecked(c_index * maxY + r_index) || isFinished
                    "
                    :tabindex="c_index * maxY + r_index"
                    class="form-control text-center"
                    type="number"
                    @keyup.enter="changed(c_index * maxY + r_index)"
                  />
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        id="resizable"
        :class="{ active: isFinished }"
        class="modal-content ui-widget-content"
      >
        <div class="modal-header ui-widget-header">
          <span>
            Jami Savollar:<span class="all_questions">
              {{ maxX * maxY }} ta
            </span>
          </span>
          <span>
            To'g'ri Javoblar:
            <span class="true_answers"> {{ list.complete.answer }} ta </span>
          </span>
          <span>
            Sarflangan Vaqt:
            <span class="time">
              {{ list.settings.number_speed - display_time }} s
            </span>
          </span>
        </div>
        <div class="modal-body">
          <table border="1">
            <thead>
              <tr>
                <th colspan="2">Noto'g'ri javoblar</th>
              </tr>
              <tr>
                <th>№</th>
                <th>=</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(answer, index) in wrong_answers" :key="index">
                <td>[{{ answer.index[1] + 1 }}, {{ answer.index[0] + 1 }}]</td>
                <td>{{ answer.result }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="game-footer">
      <div class="container game-footer__inner">
        <div class="game-footer__left">
          <div class="game-footer__points">
            <span>
              {{ list.complete.answer }}
              /
              {{ list.settings.number_count * list.settings.number_row }}
            </span>
            <span class="fas fa-clock"> : {{ display_time }} </span>
          </div>
        </div>

        <div class="game-footer__right">
          <router-link :to="{ name: 'home' }" class="btn-icon">
            <i class="fas fa-home"></i>
          </router-link>
          <router-link :to="{ name: 'list_root_settings' }" class="btn-icon">
            <i class="fas fa-cogs"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "start",
  data: () => {
    return {
      answer: [],
      checked: [],
      right_answers: [],
      wrong_answers: [],
      display_time: 0,
      interval: "",
      isFinished: false,
    };
  },
  watch: {
    not_answered: function (val) {
      if (val === 0) {
        this.submit();
      }
    },
  },
  computed: {
    maxX: function () {
      return this.list.settings.number_count;
    },
    maxY: function () {
      return this.list.settings.number_row;
    },
    not_answered: function () {
      return (
        this.list.settings.number_row * this.list.settings.number_count -
        this.checked.length
      );
    },
    list: function () {
      return this.$store.getters["root/state"].list;
    },
    table: function () {
      let numbers = this.list.numbers;
      const transpose = (matrix) => {
        let [row] = matrix;
        return row.map((value, column) => matrix.map((row) => row[column]));
      };
      return transpose(numbers);
    },
  },
  methods: {
    setFocus(index) {
      let element = document.getElementsByName("input" + index)[0];
      if (element !== undefined) {
        element.focus();
      }
    },
    submit: async function () {
      if (!this.isFinished) {
        clearInterval(this.interval);
        this.isFinished = true;
        if (await this.$store.dispatch("root/listComplete")) {
          this.$toast.success("Saqlandi");
        }
      }
    },
    start() {
      this.display_time = this.list.settings.number_speed;
      this.interval = setInterval(() => {
        if (this.display_time === 0) {
          clearInterval(this.interval);
          this.submit();
        } else {
          this.display_time--;
        }
      }, 1000);
    },
    isTrue(index) {
      return (
        this.right_answers.find((element) => {
          return element === index;
        }) !== undefined
      );
    },
    async check(r_index, c_index, answer) {
      let original_answer = parseInt(Math.sqrt(this.table[r_index][c_index]));
      let result = original_answer === parseInt(answer);
      if (result) {
        await this.$store.commit("root/INC_LIST_ANSWER");
        this.right_answers.push(c_index * this.maxY + r_index);
      } else {
        this.wrong_answers.push({
          index: [r_index, c_index],
          result: original_answer,
        });
      }

      return result;
    },
    changed(index) {
      let r_index = index % this.maxY;
      let c_index = parseInt(index / this.maxY);
      if (!this.isChecked(index)) {
        this.check(r_index, c_index, this.answer[index]);
        this.checked.push(index);
      }
      this.setFocus(index + 1);
    },
    isChecked(index) {
      return (
        this.checked.find((element) => {
          return element === index;
        }) !== undefined
      );
    },
  },
  async created() {
    if (!this.list.isReady) {
      await this.$router.push({ name: "list_root_settings" });
    } else {
      this.start();
      // eslint-disable-next-line no-undef
      $(function () {
        // eslint-disable-next-line no-undef
        $(".modal-content").draggable({
          handle: ".modal-header",
        });
        // eslint-disable-next-line no-undef
        $("#resizable").resizable();
      });
    }
  },
  async beforeDestroy() {
    clearInterval(this.interval);
    await this.$store.commit("root/CLEAR_DATA");
  },
};
</script>

<style scoped>
@media (max-width: 600px) and (min-width: 400px) {
  .wrong_answers {
    font-size: 0.7em;
  }
}

@media (min-width: 601px) {
  .wrong_answers {
    font-size: 0.2em;
  }
}

.numbers {
  display: block !important;
}

td {
  white-space: nowrap;
}

td input[type="number"] {
  padding: 0;
}

.game-main {
  margin-top: 5em;
  align-items: normal;
  max-width: 100%;
  max-height: 80%;
  overflow: auto;
  background: white;
}

.table_wrapper {
  padding: 2px;
}
</style>
